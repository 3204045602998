import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "./new-logo2.png";
import Modal from "react-bootstrap/Modal";
import { RxHamburgerMenu } from "react-icons/rx";
import { Button } from "react-bootstrap";

import "./Navigation.css";

function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false);

  const [tosOpen, setTosOpen] = useState(false);
  const handleTosClose = () => setTosOpen(false);
  const handleTosOpen = () => setTosOpen(true);

  const [gsOpen, setGsOpen] = useState (false);
  const handleGsClose = () => setGsOpen(false);
  const handleGsOpen = () => setGsOpen(true);

  return (
    <nav>
      <div className="nav-logo">
        <NavLink to="/">
          {" "}
          <img className="logo-format" src={logo} alt="logo" />{" "}
          <div className="mobile-name">Harmony</div>
        </NavLink>
      </div>
      <RxHamburgerMenu className="hamburger-button" onClick={() => {setMenuOpen(!menuOpen)}} title="Expand Menu"/>
      <ul>
        <li>
          <NavLink to="/profile">Profile</NavLink>
        </li>
        <li>
          <NavLink to="/">Prayer</NavLink>
        </li>
        <li>
          <NavLink to="/groups">Groups</NavLink>
        </li>
        <li>
          <NavLink to="/about">About Us</NavLink>
        </li>
      </ul>
      <div className="footer-div">
        <h6 className="footer-info">Harmony Prayer ® All rights reserved.</h6>
        <h6 className="footer-info">Contact us at <a className="footer-contact" href="mailto:help@harmonyprayer.com">help@harmonyprayer.com</a></h6>
        <h6 className="footer-info footer-link" onClick={handleTosOpen}>Terms of Service</h6>
        <h6 className="footer-info footer-link" onClick={handleGsOpen}>Getting Started</h6>
      </div>

      <Modal show={ menuOpen } onHide={ setMenuOpen } fullscreen={true} className="nav-modal-css">
        <Modal.Body className="nav-modal-css">
          <div className="modal-nav-logo">
            <NavLink to="/" onClick={() => {setMenuOpen(!menuOpen)}}>
              {" "}
              <img className="logo-format" src={logo} alt="logo" />{" "}
              <div className="mobile-name">Harmony</div>
            </NavLink>
          </div>
          <RxHamburgerMenu className="hamburger-button" onClick={() => {setMenuOpen(!menuOpen)}} title="Expand Menu"/>
          <div className="mobile-nav-div">
            <NavLink to="/profile" style={{display: "block"}} id="nav-one" onClick={() => {setMenuOpen(!menuOpen)}}>Profile</NavLink>
            <NavLink to="/" style={{display: "block"}} onClick={() => {setMenuOpen(!menuOpen)}}>Prayer</NavLink>
            <NavLink to="/groups" style={{display: "block"}} onClick={() => {setMenuOpen(!menuOpen)}}>Groups</NavLink>
            <NavLink to="/about" style={{display: "block"}} onClick={() => {setMenuOpen(!menuOpen)}}>About Us</NavLink>
          </div>
          <div className="modal-footer-div">
            <h6 className="modal-footer-info">Harmony Prayer ® All rights reserved.</h6>
            <h6 className="modal-footer-info">Contact us at <a className="modal-footer-contact" href="mailto:help@harmonyprayer.com">help@harmonyprayer.com</a></h6>
            <h6 className="modal-footer-info footer-link" onClick={handleTosOpen}>Terms of Service</h6>
            <h6 className="modal-footer-info footer-link" onClick={handleGsOpen}>Getting Started</h6>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={tosOpen}
        onHide={handleTosClose}
        fullscreen={true}
        className="tab-modal"
      >
        <Modal.Header closeButton closeVariant="white" className="tab-header" style={{backgroundColor: "var(--nav-background)"}}>
          <Modal.Title style={{color: "white"}}>Harmony Prayer Terms of Service</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: "var(--body-background)"}}>
          <h7 className="tab-line">
            <h5 className="header-one" style={{marginTop: "-20px"}}>OVERVIEW </h5>
            This website is operated by Harmony Prayer. Throughout the site, the
            terms “we”, “us” and “our” refer to Harmony Prayer. Harmony Prayer
            offers this website, including all information, tools and Services
            available from this site to you, the user, conditioned upon your
            acceptance of all terms, conditions, policies and notices stated
            here. By visiting our site, you engage in our “Service” and agree to
            be bound by the following terms and conditions (“Terms of Service”,
            “Terms”), including those additional terms and conditions and
            policies referenced herein and/or available by hyperlink. These
            Terms of Service apply to all users of the site, including without
            limitation to users who are browsers, vendors, customers, merchants,
            and/or contributors of content. Please read these Terms of Service
            carefully before accessing or using our website. By accessing or
            using any part of the site, you agree to be bound by these Terms of
            Service. If you do not agree to all the terms and conditions of this
            agreement, then you may not access the website or use any Services.
            If these Terms of Service are considered an offer, acceptance is
            expressly limited to these Terms of Service. Any new features or
            tools which are added to the current store shall also be subject to
            the Terms of Service. You can review the most current version of the
            Terms of Service at any time on this page. We reserve the right to
            update, change or replace any part of these Terms of Service by
            posting updates and/or changes to our website. It is your
            responsibility to check this page periodically for changes. Your
            continued use of or access to the website following the posting of
            any changes constitutes acceptance of those changes.
            <h5 className="header">SECTION 1 - ONLINE STORE TERMS </h5>
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your
            minor dependents to use this site. You may not use our products for
            any illegal or unauthorized purpose nor may you, in the use of the
            Service, violate any laws in your jurisdiction (including but not
            limited to copyright laws). You must not transmit any worms or
            viruses or any code of a destructive nature. A breach or violation
            of any of the Terms will result in an immediate termination of your
            Services.
            <h5 className="header">SECTION 2 - GENERAL CONDITIONS </h5>
            We reserve the right to refuse service to anyone for any reason at
            any time. You understand that your content (not including credit
            card information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks. You agree not to reproduce, duplicate, copy, sell, resell
            or exploit any portion of the Service, use of the Service, or access
            to the Service or any contact on the website through which the
            service is provided, without express written permission by us. The
            headings used in this agreement are included for convenience only
            and will not limit or otherwise affect these Terms.
            <h5 className="header">
              SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION{" "}
            </h5>
            We are not responsible if information made available on this site is
            not accurate, complete or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk. This site may contain certain historical information.
            Historical information, necessarily, is not current and is provided
            for your reference only. We reserve the right to modify the contents
            of this site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site.
            <h5 className="header">SECTION 4 - OPTIONAL TOOLS </h5>
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input. You acknowledge and
            agree that we provide access to such tools ”as is” and “as
            available” without any warranties, representations or conditions of
            any kind and without any endorsement. We shall have no liability
            whatsoever arising from or relating to your use of optional
            third-party tools. Any use by you of the optional tools offered
            through the site is entirely at your own risk and discretion and you
            should ensure that you are familiar with and approve of the terms on
            which tools are provided by the relevant third -party provider(s).
            We may also, in the future, offer new Services and/or features
            through the website (including, the release of new tools and
            resources). Such new features and/or Services shall also be subject
            to these Terms of Service.
            <h5 className="header">SECTION 5 - THIRD-PARTY LINKS </h5>
            Certain content, products and Services available via our Service may
            include materials from third-parties. Third-party links on this site
            may direct you to third-party websites that are not affiliated with
            us. We are not responsible for examining or evaluating the content
            or accuracy and we do not warrant and will not have any liability or
            responsibility for any third-party materials or websites, or for any
            other materials, products, or Services of third- parties. We are not
            liable for any harm or damages related to the purchase or use of
            goods, Services, resources, content, or any other transactions made
            in connection with any third-party websites. Please review carefully
            the third-party's policies and practices and make sure you
            understand them before you engage in any transaction. Complaints,
            claims, concerns, or questions regarding third-party products should
            be directed to the third-party.
            <h5 className="header">
              SECTION 6 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS{" "}
            </h5>
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments. We may, but have no obligation to, monitor,
            edit or remove content that we determine in our sole discretion to
            be unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene or otherwise objectionable or violates any
            party’s intellectual property or these Terms of Service.
          </h7>
        </Modal.Body>
        <Modal.Footer className="modal-css" style={{backgroundColor: "var(--body-background)"}}>
          <Button variant="secondary" onClick={handleTosClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={ gsOpen } onHide={ handleGsClose } fullscreen={true} className="modal-css">
        <Modal.Header closeButton closeVariant="white" className="modal-header" style={{backgroundColor: "var(--nav-background)", color: "white"}}>
            <Modal.Title id="contained-modal-title-vcenter">Getting Started with Harmony Prayer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: "var(--body-background)"}}>
            <h4>Profile Page</h4>
                <h6 className="tab-line">On the Profile Page, you are able to view and update your information.</h6>
                <h6 className="tab-line">To update or create your avatar, visit gravatar.com.</h6>
                <h6 className="tab-line">All other profile information can be updated using the Edit Profile button.</h6>
            <h4>Prayer Page</h4>
                <h6 className="tab-line">The Prayer Page is used to view your prayer requests. Requests can be filtered to show all, completed, or both. Filter options can be found by pressing the filter icon located at the top of the page.</h6>
                <h6 className="tab-line">To add a new request, press the plus icon located at the top of the page. Fill in the information and press submit to save your request.</h6>
                <h6 className="tab-line">Prayer requests are shown in a card-style view by default. To switch view, use the card and list view buttons located at the top of the page. (Note: List view is not available in Tablet and Mobile View)</h6>
                <h6 className="tab-line">Requests can be edited or deleted. To edit a request, press the three dots inside the request and select edit. Make any changes you would like to make to your request then press submit to update the request. To delete a request, press the three dots in the request, select delete, and confirm that you would like to delete your request.</h6>
                <h6 className="tab-line">The color dot inside the card view and next to the list view indicates if the request is in-progress or completed. A yellow dot indicates an in-progress request while a green dot indicated a completed request. In order to complete a request, press the three dots within the request and select complete. You may enter how your request was answered, then press submit to mark your request as completed.</h6>
                <h6 className="tab-line">You may also add comments to your requests. To make a comment, press the three dots in the request and select comments. Enter your comment and press submit to add your comment to the request.</h6>
            <h4>Groups Page</h4>
                <h6 className="tab-line">The Groups Page is a place where groups can be created to sort your prayer requests into various categories.</h6>
                <h6 className="tab-line">To view the requests in a group, simply click on the group name. To return to all groups, press the back icon located at the top of the page.</h6>
                <h6 className="tab-line">To create a new group, press the plus icon at the top of the page. Enter a name for the group and press submit to save the group.</h6>
                <h6 className="tab-line">Prayer requests can be added to a group from the Prayer Page. Select the requests you would like to add to a group using the checkbox inside the request(s). Press the folder icon at the top of the page, select which groups you would like to add the request(s) to, and press submit to add the requests to the group.</h6>
            <h4>About Us Page</h4>
                <h6 className="tab-line">The About Us page showcases each of the team memebers who have been a part of the development of Harmony Prayer. Each team member has a photo as well as a brief description of thier role within the development team.</h6>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "var(--body-background)"}}>
            <Button variant="secondary" onClick={handleGsClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
    </nav>
  );
}

export default Navigation;
