import "./SignOutButton.css";
import { Button } from "react-bootstrap";

import { signOut } from "aws-amplify/auth";

function SignOutButton() {
  return (
    <div>
      <Button className="sign-out-button" onClick={signOut}>
        Sign Out
      </Button>
    </div>
  );
}

export default SignOutButton;
