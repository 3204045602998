import React from "react";
import PrayerList from "../components/Prayer Request/PrayerList";
import "./PrayerRequestPage.css";

// This component renders the Prayer Request Page, it also performs dynamic tasks
// which are described below.
function PrayerRequestPage(props) {
  // Renders the Search Bar and Prayer List.
  return (
    <div className="page-layout">
      <div style={{ overflow: "none", display: "block", width: "100%", height: "100vh"}}>
        <PrayerList
          inProgressRequests={props.inProgressRequests}
          prayerRequests={props.prayerRequests}
          completeRequests={props.completeRequests}
          allRequests={props.allRequests}
          groupName={"Prayer Requests"}
          groups={props.groups}
          createPrayerRequest={props.createPrayerRequest}
          deletePrayerRequest={props.deletePrayerRequest}
          updatePrayerRequest={props.updatePrayerRequest}
          comment={props.comment}
        />
      </div>
    </div>
  );
}

export default PrayerRequestPage;
