// Import necessary dependencies and components
import { IoFilterSharp } from "react-icons/io5";
import "./PrayerListTitleBar.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import CreateRequestModal from "./CreateRequestModal";
import { GoPlus } from "react-icons/go";
import { MdList } from "react-icons/md";
import { TbBoxMultiple } from "react-icons/tb";
import { FaFolderPlus } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as mutation from "../../graphql/mutations";
import { Amplify } from "aws-amplify";
import config from "../../amplifyconfiguration.json";
import { generateClient } from "aws-amplify/api";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { fetchUserAttributes } from "aws-amplify/auth";
import { FaFolderMinus } from "react-icons/fa6";

Amplify.configure(config);
// Generate an API client to interact with the GraphQL API
const client = generateClient();

// Define the PrayerListTitleBar functional component
function PrayerListTitleBar(props) {
  // State variables for modals and group selection
  const [showModal, setShowModal] = useState(false);
  const [showGroupModal, setGroupModal] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(new Set());
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showRemoveFromGroupModal, setRemoveFromGroupModal] = useState(false);
  const [currentVersion, setVersion] = useState();
  const [currentGroupID, setCurrentGroupID] = useState();
  //const [newTitle, setTitle] = useState(props.groupName);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({ groupName: props.groupName });

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
    filter.removeWords('God');

  const validateForm = (form) => {
    const { groupName } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (!groupName || groupName === "" || groupName.length >= 50)
      newErrors.groupName =
        "Please enter a valid group name (1 to 50 characters).";
    if (groupName?.trim().length === 0) 
      newErrors.groupName = "Please enter a valid name. Not just whitespace.";
    if (specialChars.test(groupName)) newErrors.groupName = charMessage;
    if (filter.isProfane(groupName))
      newErrors.groupName = "Please do not use profanity in your group name.";
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  // Handle group selection in the modal
  const handleGroupSelection = (id) => {
    if (selectedGroups.has(id)) {
      selectedGroups.delete(id);
    } else {
      selectedGroups.add(id);
    }
    setSelectedGroups(new Set(selectedGroups));
  };

  // Handle group title change
  const groupTitleChangeHandler = async () => {
    try {
      // Retrieve the user's email
      const { email } = await fetchUserAttributes();
      const formErrors = validateForm(form);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        // Prepare the updated group details
        const updateGroupDetails = {
          _version: currentVersion,
          id: currentGroupID,
          groupName: form.groupName,
          user: email,
        };

        // Update the group details using GraphQL mutation
        await client.graphql({
          query: mutation.updateUserPrayerGroups,
          variables: {
            input: updateGroupDetails,
          },
        });
        // Fetch updated category
        props.setCategory(form.groupName);
        // Close the edit modal
        setEditModal(false);
      }
    } catch (error) {
      // Handle errors
      console.error("Error updating group details:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  // Handle assigning prayer requests to groups
  const assignGroupHandler = async () => {
    // Close the group modal
    setGroupModal(false);
    // Filter through groups grabbing only selected groups
    const selectedGroupDetails = props.groups.filter((value) =>
      selectedGroups.has(value.id)
    );
    // For every selected group we add the selected prayer requests
    selectedGroupDetails.forEach(async (prayerGroup) => {
      // Prepare the details for updating the prayers in the group
      const updateGroupDetails = {
        id: prayerGroup.id,
        prayerIds: [
          ...prayerGroup.prayerIds.split(","),
          ...props.selectedList,
        ].join(","),
        _version: prayerGroup._version,
      };
      // Update the array of prayer request ids
      await client.graphql({
        query: mutation.updateUserPrayerGroups,
        variables: {
          input: updateGroupDetails,
        },
      });
      // Reset the selected list and selected groups after all associations are created
      props.setSelectedList(new Set());
      setSelectedGroups(new Set());
    });
  };

  const removeFromGroupHandler = async () => {
    try {
      // Close the group modal
      setRemoveFromGroupModal(false);
      const selectedGroupDetails = props.groups.filter((value) =>
        selectedGroups.has(value.id)
      );
      const newList = selectedGroupDetails[0].prayerIds
        .split(",")
        .filter((value) => !props.selectedList.has(value));
      const updateGroupDetails = {
        id: selectedGroupDetails[0].id,
        prayerIds: newList.join(","),
        _version: selectedGroupDetails[0]._version,
      };
      // Update the array of prayer request ids
      await client.graphql({
        query: mutation.updateUserPrayerGroups,
        variables: {
          input: updateGroupDetails,
        },
      });

      // Reset the selected list and selected groups after all associations are created
      props.setSelectedList(new Set());
      setSelectedGroups(new Set());
    } catch (error) {
      // Handle errors
      console.error("Error assigning groups to prayer requests:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  const returnToGroups = () => {
    // Update the category to "groups" and set prayer requests to all requests
    props.setCategory("groups");
  };

  // Handle deleting a group
  const deleteGroupHandler = () => {
    // Show the delete confirmation modal
    setDeleteModal(true);
    // Find the ID and version of the current group
    props.groups?.forEach((data) => {
      if (data.groupName === props.groupName) {
        setCurrentGroupID(data.id);
        setVersion(data._version);
        //setTitle(data.groupName);
      }
    });
  };

  // Handle deleting the group
  const deleteGroup = async () => {
    try {
      // Prepare the details for deleting the group
      const deleteGroupDetails = {
        _version: currentVersion,
        id: currentGroupID,
      };
      // Delete the group using GraphQL mutation
      await client.graphql({
        query: mutation.deleteUserPrayerGroups,
        variables: {
          input: deleteGroupDetails,
        },
      });
      // Update the category to "groups" and fetch updated groups
      props.setCategory("groups");
    } catch (error) {
      // Handle errors
      console.error("Error deleting group:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  const prepareRemoveFromGroupModal = () => {
    setRemoveFromGroupModal(true);
    props.groups.forEach((val) => {
      if (val.groupName === props.groupName) {
        selectedGroups.add(val.id);
      }
    });
    setSelectedGroups(new Set(selectedGroups));
  };

  // Handle editing a group
  const editHandler = () => {
    // Show the edit modal
    setEditModal(true);
    // Find the ID and version of the current group
    props.groups.forEach((data) => {
      if (data.groupName === props.groupName) {
        setCurrentGroupID(data.id);
        setVersion(data._version);
      }
    });
  };
  window.addEventListener("resize", () => {
    if (window.innerWidth < 1201) {
      props.setListStyle(false);
    }
  });

  // JSX for rendering the title bar and its components
  return (
    <div className="title-bar">
      <h1>{props.groupName}</h1>
      <div className="button-div">
        {props.groupName === "Prayer Requests" &&
          props.selectedList.size > 0 && (
            <Button title="Add to a group" onClick={() => setGroupModal(true)} className="button">
              <FaFolderPlus style={{ color: "white", fontSize: "32px" }} />
            </Button>
          )}
        {props.groupName !== "Prayer Requests" &&
          props.selectedList.size > 0 && (
            <Button
            title="Remove from a group"
              onClick={() => prepareRemoveFromGroupModal()}
              className="button"
            >
              <FaFolderMinus style={{ color: "white", fontSize: "32px" }} />
            </Button>
          )}
        <Button
          onClick={() => props.setListStyle(true)}
          className="button"
          id="list-button"
          title="View as list"
        >
          <MdList style={{ color: "white", fontSize: "32px" }} />
        </Button>
        {/* Button to toggle card view */}
        <Button title="View as tiles" onClick={() => props.setListStyle(false)} className="button">
          <TbBoxMultiple style={{ color: "white", fontSize: "32px" }} />
        </Button>
        {/* Show the edit button if not on the "Prayer Requests" page */}
        {props.groupName !== "Prayer Requests" && (
          <Button title="Edit group name" className="button" onClick={() => editHandler()}>
            <CiEdit style={{ color: "white", fontSize: "32px" }} />
          </Button>
        )}
        {/* Show the delete button if not on the "Prayer Requests" page */}
        {props.groupName !== "Prayer Requests" && (
          <Button title="Delete group" className="button" onClick={() => deleteGroupHandler()}>
            <FaRegTrashAlt style={{ color: "white", fontSize: "28px" }} />
          </Button>
        )}
        {/* Show the back button if not on the "Prayer Requests" page */}
        {props.groupName !== "Prayer Requests" && (
          <Button title="Return to group list" className="button" onClick={() => returnToGroups()}>
            <IoMdArrowBack style={{ color: "white", fontSize: "32px" }} />
          </Button>
        )}
        {/* Show the "Create Prayer Request" button if on the "Prayer Requests" page */}
        {props.groupName === "Prayer Requests" && (
          <Button title="Add prayer request" onClick={() => setShowModal(true)} className="button">
            <GoPlus style={{ color: "white", fontSize: "32px" }} />
          </Button>
        )}
        {/* Dropdown for filtering prayer requests */}
        {props.groupName === "Prayer Requests" && (
          <Dropdown className="filter-controls">
            <Dropdown.Toggle
              title="Filter requests"
              style={{ width: "100%" }}
              className="button"
              id="dropdown-basic"
            >
              <IoFilterSharp style={{ color: "white", fontSize: "32px" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => props.setFilter("all")}>
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={() => props.setFilter("completed")}>
                Completed
              </Dropdown.Item>
              <Dropdown.Item onClick={() => props.setFilter("in-progress")}>
                In Progress
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {/* Modals for group selection, creating a request, deleting a group, and editing a group */}
      <Modal show={showGroupModal} onHide={() => setGroupModal(false)}>
        <Modal.Header>Select Groups</Modal.Header>
        <Modal.Body>
          {props.groups
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((data) => (
              <div key={data.id} style={{ display: "flex" }}>
                <Form.Check
                  style={{marginTop: '5px'}}
                  onClick={() => handleGroupSelection(data.id)}
                />
                <p>{data.groupName}</p></div> 
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => assignGroupHandler()}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showRemoveFromGroupModal}
        onHide={() => setRemoveFromGroupModal(false)}
      >
        <Modal.Header>Remove from Group Confirmation</Modal.Header>
        <Modal.Body>
          Are you sure you want to remove the selected requests from this group?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => removeFromGroupHandler()}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <CreateRequestModal
        createPrayerRequest={props.createPrayerRequest}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Modal
        style={{ marginTop: "10%" }}
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete this prayer group?</p>
          <div style={{ display: "flex" }}>
          <Button style={{ marginRight: "10px" }} variant="secondary" onClick={() => setDeleteModal(false)}>
              Close
            </Button>
            <Button
              onClick={() => deleteGroup()}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showEditModal} onHide={() => setEditModal(false)}>
        <Modal.Header>Edit Group Name</Modal.Header>
        <Form noValidate className="signup-form">
          <Form.Group
            className="data-input"
            md="4"
            controlId="validationCustom01"
          >
            <Form.Control
              value={form.groupName}
              onChange={(e) =>
                setField(
                  "groupName",
                  e.target.value,
                  setForm,
                  setErrors,
                  form,
                  errors
                )
              }
              type="title"
              isInvalid={!!errors.groupName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.groupName}
            </Form.Control.Feedback>
          </Form.Group>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            
            <Button style={{ marginRight: "8px" }} variant="secondary" onClick={() => setEditModal(false)}>
              Close
            </Button>
            <Button
              onClick={() => groupTitleChangeHandler()}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

// Export the PrayerListTitleBar component
export default PrayerListTitleBar;
