// Import necessary dependencies and components
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ModalControlButtons from "./ModalControlButtons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./CreateUserModal.css";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import * as mutation from "../graphql/mutations";
import config from "../amplifyconfiguration.json";
import { fetchUserAttributes } from "aws-amplify/auth";

Amplify.configure(config);
const client = generateClient();

// Define the CreateUserModal component
const CreateUserModal = (props) => {
  // State variables for form errors and form data
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
    filter.removeWords('God');

  const validateForm = (form) => {
    const { firstName, lastName, bio, age } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (
      !firstName ||
      firstName === "" ||
      firstName.length >= 14  ||
      firstName.length < 2
    )
      newErrors.firstName =
        "Invalid first name (2 to 14 characters).";
    if (firstName.indexOf(' ') >= 0) 
        newErrors.firstName = "Please enter a valid first name. No whitespace.";
    if (specialChars.test(firstName)) newErrors.firstName = charMessage;
    if (filter.isProfane(firstName))
      newErrors.lastName = "Please do not use profanity in your first name.";
    if (
      !lastName ||
      lastName === "" ||
      lastName.length >= 14  ||
      lastName.length < 2
    )
      newErrors.lastName =
        "Invalid last name (2 to 14 characters).";
    if (lastName.indexOf(' ') >= 0) 
        newErrors.lastName = "Please enter a valid last name. No whitespace.";
    if (specialChars.test(lastName)) newErrors.lastName = charMessage;
    if (filter.isProfane(lastName))
      newErrors.bio = "Please do not use profanity in your last name.";
    if (!bio || bio === "" || bio.length >= 100 || bio.length < 10)
      newErrors.bio = "Please enter a valid comment (10 to 100 characters).";
    if (bio?.trim().length === 0) 
      newErrors.bio = "Please enter a valid bio. No whitespace.";
    if (specialChars.test(bio)) newErrors.bio = charMessage;
    if (filter.isProfane(bio))
      newErrors.age = "Please do not use profanity in your biography.";
    if (!age || age === "" || age < 10 || age > 99) {
      newErrors.age = "Please enter a valid age (10 to 99 years old).";
    }
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleClose = () => {
    props.setCreateModalVis(false);
    setErrors({});
  }

  const handleUserEdit = async (e) => {
    e.preventDefault();
    try {
      const { email } = await fetchUserAttributes();
      const formErrors = validateForm(form);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        const data = {
          firstName: form.firstName,
          lastName: form.lastName,
          email: email,
          age: form.age,
          bio: form.bio,
        };
        const promise = await client.graphql({
          query: mutation.createUser,
          variables: { input: data },
        });

        // Wait for the promise to resolve
        await promise;
        props.setCreateModalVis(false)
        setForm({});
      }
    } catch (error) {
      // Handle errors
      console.error("Error creating/editing user:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  // Render the modal dialog box
  return (
    <Modal
      show={props.createModalVis}
      onHide={() => handleClose()}
    >
      <Modal.Header>About Me</Modal.Header>

      {/* Form for entering your personal information */}
      <Form
        noValidate
        className="userBio-form"
        onSubmit={(e) => handleUserEdit(e)}
      >
        {/* Input field for the title of the prayer request */}
        <Row>
          <Col>
            <Form.Label id="NameTitle">Your Name</Form.Label>
            <Form.Control
              onChange={(e) =>
                setField(
                  "firstName",
                  e.target.value,
                  setForm,
                  setErrors,
                  form,
                  errors
                )
              }
              type="text"
              placeholder="First Name"
              id="userfName"
              isInvalid={!!errors.firstName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Control
              onChange={(e) =>
                setField(
                  "lastName",
                  e.target.value,
                  setForm,
                  setErrors,
                  form,
                  errors
                )
              }
              type="text"
              placeholder="Last Name"
              id="userlName"
              isInvalid={!!errors.lastName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label id="ageTitle">Age</Form.Label>
          <Form.Control
            onChange={(e) =>
              setField("age", e.target.value, setForm, setErrors, form, errors)
            }
            type="number"
            placeholder="Your Age"
            isInvalid={!!errors.age}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.age}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Textarea field for the biography */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label id="testimony-Title">Bio</Form.Label>
          <Form.Control
            onChange={(e) =>
              setField("bio", e.target.value, setForm, setErrors, form, errors)
            }
            as="textarea"
            placeholder="Enter your testimony."
            type="note"
            isInvalid={!!errors.bio}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.bio}
          </Form.Control.Feedback>
        </Form.Group>
        <ModalControlButtons
          style={{ margin: "15px 5px 15px 0" }}
          closeButton={() => handleClose()}
        />
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
