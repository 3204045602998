// Import necessary dependencies and components
import ModalControlButtons from "../ModalControlButtons";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import * as util from "../../utils/PrayerUtils";
import { useState } from "react";

// Define the EditRequestModal component
const EditRequestModal = (props) => {
  // State variable for form errors
  const [errors, setErrors] = useState({});

  // Render the modal dialog box
  return (
    <Modal
      show={props.isVisible}
      onHide={() => util.handleClose(props.setShowModal, setErrors)}
    >
      {/* Modal header */}
      <Modal.Header>Edit Prayer Request</Modal.Header>
      {/* Form for editing the prayer request */}
      <Form
        className="signup-form"
        noValidate
        onSubmit={(event) =>
          util.handleUpdate(
            event,
            props.form,
            props.setShowModal,
            setErrors,
            props.currentID,
            props.version,
            props.updatePrayerRequest
          )
        }
      >
        {/* Input field for updating the title */}
        <Form.Group
          className="data-input"
          md="4"
          controlId="validationCustom01"
        >
          <Form.Label>Update Title</Form.Label>
          <Form.Control
            onChange={(e) =>
              util.setField(
                "title",
                e.target.value,
                props.setForm,
                setErrors,
                props.form,
                errors
              )
            }
            type="title"
            value={props.form.title}
            isInvalid={!!errors.title}
            required
          />

          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Input field for updating the description */}
        <Form.Group
          className="data-input"
          md="4"
          controlId="validationCustom02"
        >
          <Form.Label>Update Description</Form.Label>
          <Form.Control
            onChange={(e) =>
              util.setField(
                "note",
                e.target.value,
                props.setForm,
                setErrors,
                props.form,
                errors
              )
            }
            as="textarea"
            value={props.form.note}
            isInvalid={!!errors.note}
            required
          />

          <Form.Control.Feedback type="invalid">
            {errors.note}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Modal control buttons */}
        <div className="data-input">
          <ModalControlButtons
            style={{ margin: "15px 5px 15px 0" }}
            closeButton={() => util.handleClose(props.setShowModal, setErrors)}
          />
        </div>
      </Form>
    </Modal>
  );
};

// Export the EditRequestModal component
export default EditRequestModal;