import "./Profile.css";
import UserProfile from "../components/UserProfile";

function Profile(props) {
  return (
    <div className="profile-page-layout">
      <UserProfile className="user-profile" userData={props.userData} />
    </div>
  );
}

export default Profile;
