// Import necessary dependencies and components
import React from "react";
import "./GroupTitleBar.css";
import Button from "react-bootstrap/Button";
import { GoPlus } from "react-icons/go";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

// Define the GroupTitleBar component
function GroupTitleBar(props) {
  // Render the title bar
  return (
    <div className="group-title-bar">
      {/* Title of the group list */}
      <p style={{ color: "white", fontSize: "32px", paddingLeft: "30px" }}>
        Prayer Groups
      </p>
      {/* Button to create a new group */}
      <div className="group-div">
        <Button
          title="Add a new group"
          onClick={() => props.setShowModal(true)}
          className="group-button"
        >
          {/* Plus icon */}
          <GoPlus style={{ color: "white", fontSize: "32px" }} />
        </Button>
      </div>

      {/* Modal for creating a new group */}
      <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
        {/* Modal header */}
        <Modal.Header>Create Group</Modal.Header>
        {/* Modal body */}
        <Modal.Body>
          <Form noValidate onSubmit={(event) => props.handleGroupCreation(event)}>
            {/* Input field for the group title */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="title"
                placeholder="Enter a name"
                onChange={(e) =>
                  props.setField(
                    "groupName",
                    e.target.value,
                    props.setForm,
                    props.setErrors,
                    props.form,
                    props.errors
                  )
                }
                isInvalid={!!props.errors.groupName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.groupName}
              </Form.Control.Feedback>
            </Form.Group>
            <div> 
            <Button
              variant="secondary"
              style={{marginRight: '5px'}}
              onClick={() => props.setShowModal(false)}
            >
              Close
            </Button>
            <Button type='submit'>Submit</Button>
          </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

// Export the GroupTitleBar component
export default GroupTitleBar;
