import "./PageNotFound.css";

// This function will render the About Us page it also contains the dynamic
// MemberCard component which renders each member of the team.
function PageNotFound() {
  return (
    <div className="error-page-layout">
       <h2>404 - Page Not Found</h2>
      <h3>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h3>
    </div>
  );
}

export default PageNotFound;