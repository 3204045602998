// Import necessary dependencies and components
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
//import * as util from "../../utils/PrayerUtils";
import ModalControlButtons from "../ModalControlButtons";
import * as mutation from "../../graphql/mutations";
//import { fetchUserAttributes } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import "./CreateRequestModal.css";

// Generate an API client using AWS Amplify
const client = generateClient();

// Define the CommentRequestModal component
const CommentRequestModal = (props) => {
  // State variables for form errors and comment notes
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
    filter.removeWords('God');

  const validateForm = (form) => {
    const { comment } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (!comment || comment === "" || comment.length >= 100)
      newErrors.comment = "Please enter a valid comment (1 to 100 characters).";
    if (comment?.trim().length === 0) 
      newErrors.comment = "Please enter a valid comment. Not just whitespace.";
    if (specialChars.test(comment)) newErrors.comment = charMessage;
    if (filter.isProfane(comment))
      newErrors.comment = "Please do not use profanity in your comment.";
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  // Function to create a new comment
  async function createComments(event) {
    try {
      event.preventDefault();
      // Prepare the data for the new comment
      const formErrors = validateForm(form);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        const data = {
          note: form.comment,
          prayerrequestID: props.id,
        };
        // Send a GraphQL mutation to create the comment
        const promise = await client.graphql({
          query: mutation.createComments,
          variables: { input: data },
        });
        // Wait for the promise to resolve
        await promise;
        // Close the modal
        props.setCommentVis(false);
        setForm({});
      }
    } catch (error) {
      // Handle errors
      console.error("Error creating comment:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  // Render the modal dialog box
  return (
    <Modal
      show={props.commentModalVis}
      onHide={() => props.setCommentVis(false)}
    >
      {/* Modal header */}
      <Modal.Header>Comments on Prayer Request</Modal.Header>
      {/* Form for adding a comment */}
      <Form
        noValidate
        className="signup-form"
        onSubmit={(event) => createComments(event)}
      >
        {/* Textarea field for the comment */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            type="comment"
            placeholder="Comment"
            as="textarea"
            onChange={(e) =>
              setField(
                "comment",
                e.target.value,
                setForm,
                setErrors,
                form,
                errors
              )
            }
            isInvalid={!!errors.comment}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.comment}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Modal control buttons */}
        <ModalControlButtons
          style={{ margin: "15px 5px 15px 0" }}
          closeButton={() => props.setCommentVis(false)}
        />
      </Form>
    </Modal>
  );
};

// Export the CommentRequestModal component
export default CommentRequestModal;
