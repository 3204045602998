/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComments = /* GraphQL */ `
  subscription OnCreateComments(
    $filter: ModelSubscriptionCommentsFilterInput
    $owner: String
  ) {
    onCreateComments(filter: $filter, owner: $owner) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateComments = /* GraphQL */ `
  subscription OnUpdateComments(
    $filter: ModelSubscriptionCommentsFilterInput
    $owner: String
  ) {
    onUpdateComments(filter: $filter, owner: $owner) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteComments = /* GraphQL */ `
  subscription OnDeleteComments(
    $filter: ModelSubscriptionCommentsFilterInput
    $owner: String
  ) {
    onDeleteComments(filter: $filter, owner: $owner) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserPrayerGroups = /* GraphQL */ `
  subscription OnCreateUserPrayerGroups(
    $filter: ModelSubscriptionUserPrayerGroupsFilterInput
    $owner: String
  ) {
    onCreateUserPrayerGroups(filter: $filter, owner: $owner) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserPrayerGroups = /* GraphQL */ `
  subscription OnUpdateUserPrayerGroups(
    $filter: ModelSubscriptionUserPrayerGroupsFilterInput
    $owner: String
  ) {
    onUpdateUserPrayerGroups(filter: $filter, owner: $owner) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserPrayerGroups = /* GraphQL */ `
  subscription OnDeleteUserPrayerGroups(
    $filter: ModelSubscriptionUserPrayerGroupsFilterInput
    $owner: String
  ) {
    onDeleteUserPrayerGroups(filter: $filter, owner: $owner) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreatePrayerRequest = /* GraphQL */ `
  subscription OnCreatePrayerRequest(
    $filter: ModelSubscriptionPrayerRequestFilterInput
    $owner: String
  ) {
    onCreatePrayerRequest(filter: $filter, owner: $owner) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePrayerRequest = /* GraphQL */ `
  subscription OnUpdatePrayerRequest(
    $filter: ModelSubscriptionPrayerRequestFilterInput
    $owner: String
  ) {
    onUpdatePrayerRequest(filter: $filter, owner: $owner) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeletePrayerRequest = /* GraphQL */ `
  subscription OnDeletePrayerRequest(
    $filter: ModelSubscriptionPrayerRequestFilterInput
    $owner: String
  ) {
    onDeletePrayerRequest(filter: $filter, owner: $owner) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
