import Button from "react-bootstrap/Button";

const ModalControlButtons = (props) => {
    return(
    <div>
        <Button
            style={props.style}
            variant="secondary"
            onClick={props.closeButton}
          >
            Close
          </Button>
          <Button
            style={props.style}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
    </div>);
    
} 
export default ModalControlButtons;
