import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { format } from "date-fns";
import { FaRegTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import Form from "react-bootstrap/Form";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import * as mutation from "../../graphql/mutations";
import config from "../../amplifyconfiguration.json";

Amplify.configure(config);
const client = generateClient();

const ViewCommentsModal = (props) => {
  const [currentCommentID, setCommentID] = useState();
  const [currentVersion, setVersion] = useState();
  const [currentPrayerID, setPrayerID] = useState();
  const [deleteModalVis, setDeleteModalVis] = useState(false);
  const [showEditBox, setEditBox] = useState(false);
  // State variables for form errors and completed note
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
    filter.removeWords('God');

  const validateForm = (form) => {
    const { comment } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (!comment || comment === "" || comment.length >= 100)
      newErrors.comment = "Please enter a valid comment (1 to 100 characters).";
    if (specialChars.test(comment)) newErrors.comment = charMessage;
    if (comment?.trim().length === 0) 
      newErrors.comment = "Please enter a valid comment. Not just whitespace.";
    if (filter.isProfane(comment))
      newErrors.comment = "Please do not use profanity in your comment.";
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const editHandler = (id, version, prayerID, note) => {
    setCommentID(id);
    setVersion(version);
    setPrayerID(prayerID);
    setField("comment", note, setForm, setErrors, form, errors);
    setEditBox(true);
  };
  const handleEditClose = () => {
    props.setViewCommentModalVis(false);
    setCommentID("");
    setEditBox(false);
  };

  const handleDelete = (id, version) => {
    setDeleteModalVis(true);
    setCommentID(id);
    setVersion(version);
    setEditBox(false);
  };

  const handleCommentUpdate = async () => {
    try {
      const formErrors = validateForm(form);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        const updateCommentVariables = {
          id: currentCommentID,
          note: form.comment,
          _version: currentVersion,
          prayerrequestID: currentPrayerID,
        };
        await client.graphql({
          query: mutation.updateComments,
          variables: { input: updateCommentVariables },
        });
        setCommentID("");
        setForm({});
      }
    } catch (error) {
      console.error("Error updating comment:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  const confirmDeleteHandler = async () => {
    try {
      const deleteCommentVariables = {
        id: currentCommentID,
        _version: currentVersion,
      };

      await client.graphql({
        query: mutation.deleteComments,
        variables: { input: deleteCommentVariables },
      });
      setDeleteModalVis(false);
      props.setViewCommentModalVis(false);
    } catch (error) {
      console.error("Error confirming delete:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };
  return (
    <div>
      <Modal show={props.viewCommentsModalVis} onHide={() => handleEditClose()}>
        <Modal.Header>View Comments</Modal.Header>
        <Modal.Body>
          {/* Comments on the prayer request */}
          {/*eslint-disable-next-line */}
          {props.comment?.map((value) => {
            if (value.prayerrequestID === props.currentID) {
              return (
                <div key={value.id}>
                  <div style={{ display: "flex", position: "relative" }}>
                    {(value.id === currentCommentID && showEditBox) ? (
                      <div>
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              type="comment"
                              value={form.comment}
                              onChange={(e) =>
                                setField(
                                  "comment",
                                  e.target.value,
                                  setForm,
                                  setErrors,
                                  form,
                                  errors
                                )
                              }
                              isInvalid={!!errors.comment}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.comment}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form>
                        <Button onClick={() => handleCommentUpdate()}>
                          Submit
                        </Button>
                      </div>
                    ) : (
                      value.note
                    )}
                    <Button
                      onClick={
                        value.id === currentCommentID
                          ? () => setCommentID("")
                          : () =>
                              editHandler(
                                value.id,
                                value._version,
                                value.prayerrequestID,
                                value.note
                              )
                      }
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "-47px",
                        marginRight: "45px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <CiEdit style={{ color: "black", fontSize: "24px" }} />
                    </Button>
                    <Button
                      onClick={() =>
                        handleDelete(
                          value.id,
                          value._version,
                          value.prayerrequestID
                        )
                      }
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "-45px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <FaRegTrashAlt
                        style={{ color: "black", fontSize: "22px" }}
                      />
                    </Button>
                  </div>

                  <p style={{ marginTop: "10px" }}>
                    {format(value.createdAt, "h:mmaaaaa'm'")}
                    <br />
                    {format(value.createdAt, "MMMM dd, yyyy")}
                  </p>
                  <br />
                </div>
              );
            }
          })}
        </Modal.Body>
      </Modal>
      <Modal show={deleteModalVis} onHide={() => setDeleteModalVis(false)}>
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete this comment?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => confirmDeleteHandler()}>Confirm</Button>
          <Button variant="secondary" onClick={() => setDeleteModalVis(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewCommentsModal;
