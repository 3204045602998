// Import necessary dependencies and components
import React from "react";
import "./GroupList.css";

// Define the GroupList component
function GroupList(props) {
  // Function to handle group display
  const handleGroupDisplay = (name, id) => {
    props.setCategory(name);
    props.setGroupId(id);
  };

  return (
    <div className="group-list-page">
      {/* Render the list of groups if the category is "groups" */}
      {props.showCategory === "groups" &&
        props.groups
          ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((data) => (
            <div
              title="Expand group"
              onClick={() => handleGroupDisplay(data.groupName, data.id)}
              key={data.id}
              style={{
                textAlign: "center",
                paddingTop: "20px",
                display: "flex",
              }}
            >
              <p className="group-item">{data.groupName}</p>
            </div>
          ))}
    </div>
  );
}

// Export the GroupList component
export default GroupList;
