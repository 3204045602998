import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import config from "../amplifyconfiguration.json";
import { listUserPrayerGroups } from "../graphql/queries";
import { fetchUserAttributes } from "aws-amplify/auth";

Amplify.configure(config);
const client = generateClient();

export const fetchGroups = async (setGroups) => {
  try {
    const { email } = await fetchUserAttributes();
    const filter = {
      filter: {
        user: { eq: email },
        and: { _deleted: { ne: true } },
      },
    };
    const promise = await client.graphql({
      query: listUserPrayerGroups,
      variables: filter,
    });
    // Wait for the promise to resolve
    await promise;

    const groupsFromAPI = promise.data.listUserPrayerGroups.items;

    setGroups(groupsFromAPI);
  } catch (error) {
    console.error("Error fetching groups:", error);

    // Check for network errors
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error(
        "Network error occurred. Please check your internet connection."
      );
    } else {
      // Handle other unexpected errors
      console.error("An unexpected error occurred:", error.message);
    }
  }
};
