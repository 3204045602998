import "./MemberCard.css";

const MemberCard = (props) => {
  return (
    <div className="card">
      <img
        src={props.image}
        alt={props.name}
        style={{ width: "30%" }}
        className="profile-image"
      />
        <h3>{props.name}</h3>
        <div className="container">
          <p className="title">{props.title}</p>
          <p>{props.description}</p>
        </div>
      </div>
  );
};

export default MemberCard;
