import { format } from "date-fns";
import * as util from "../../utils/PrayerUtils";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PiDotsThree } from "react-icons/pi";
import { MdExpandMore } from "react-icons/md";
import { Button } from "react-bootstrap";
import { MdCircle } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";

const RenderAsList = (props) => {
  return (
    <div>
      <Row
        className="prayer-requests-list-view"
        style={{ backgroundColor: "transparent" }}
      >
        {/* Table headers */}
        <Col lg="1"></Col>
        <Col lg="2">
          <h5>Title</h5>
        </Col>
        <Col lg="2">
          <h5>Description</h5>
        </Col>
        <Col lg="2">
          <h5>Answer</h5>
        </Col>
        <Col lg="2">
          <h5>Comments</h5>
        </Col>
        <Col lg="1">
          <h5>Date</h5>
        </Col>
        <Col lg="1"></Col>
        <Col lg="1"></Col>
      </Row>
      {/* Render prayer requests in a list or grid view */}
      {props.prayerRequests
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map((data) => (
          <Row key={data.id} className="prayer-requests-list-view">
            {/* Checkbox and status indicator */}
            <Col
              lg="1"
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Unchecked checkbox */}
              {!props.selectedList.has(data.id) ? (
                <MdOutlineCheckBoxOutlineBlank
                  className="checkbox"
                  title="Select for grouping"
                  onClick={() => props.handleSelection(data.id)}
                />
              ) : (
                <MdOutlineCheckBox
                  onClick={() => props.handleSelection(data.id)}
                  className="checkbox"
                  title="Selected for grouping"
                />
              )}

              <MdCircle
                title={
                  data.completed ? "Status: Completed" : "Status: In Progress"
                }
                style={{
                  color: data.completed ? "green" : "yellow",
                  fontSize: "20px",
                  paddingLeft: "5px",
                }}
              />
            </Col>
            {/* <Col></Col> */}
            {/* Prayer request title */}
            <Col lg="2">
              <p>
                {data.title.length > 15
                  ? `${data.title.substring(0, 15)}...`
                  : data.title}
              </p>
            </Col>
            {/* Prayer request description */}
            <Col lg="2">
              <p>
                {data.note.length > 15
                  ? `${data.note.substring(0, 15)}...`
                  : data.note}
              </p>
            </Col>
            {/* Answer to the prayer request */}
            <Col lg="2">
              <p>
                {data.completed
                  ? data?.completedNote?.length > 15
                    ? `${data?.completedNote?.substring(0, 15)}...`
                    : data?.completedNote
                  : "n/a"}
              </p>
            </Col>
            {/* Comments expansion */}
            <Col lg="2">
               {/* Comments on the prayer request */}
        {props.comment?.some((value) => value.prayerrequestID === data.id) && (
          <>
            <button
              className="view-more"
              onClick={() => props.expandCommentsModal(data.id)}
              style={{marginBottom: "15px",}}
            >
              View More
            </button>
          </>
        )}
            </Col>
            {/* Date of creation */}
            <Col lg="1">
              <p>{format(data.createdAt, " MMMM dd")}</p>
            </Col>
            {/* Expand button */}
            <Col lg="1">
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  position: "absolute",
                  bottom: "-7px",
                  right: "55px",
                }}
                onClick={() =>
                  props.handleExpansion(
                    data.title,
                    data.note,
                    data.completedNote,
                    data.createdAt,
                    data.completed,
                    data.id
                  )
                }
              >
                <MdExpandMore
                  title="Expand request"
                  style={{
                    color: "black",
                    fontSize: "32px",
                  }}
                />
              </Button>
            </Col>
            {/* Dropdown menu for actions */}
            <Col lg="1">
              <Dropdown>
                <Dropdown.Toggle className="three-buttons">
                  <PiDotsThree
                    style={{
                      position: "absolute",
                      bottom: "4px",
                      right: "15px",
                      color: "black",
                      fontSize: "32px",
                    }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* Edit prayer request */}
                  <Dropdown.Item
                    onClick={() =>
                      util.editPrayerRequest(
                        data.id,
                        data.title,
                        data.note,
                        data._version,
                        props.setShowModal,
                        props.setID,
                        props.setForm,
                        props.setVersion,
                        props.form
                      )
                    }
                  >
                    Edit
                  </Dropdown.Item>
                  {/* Delete prayer request */}
                  <Dropdown.Item
                    onClick={() =>
                      util.deletePrayerRequestConfirmationHandler(
                        data.id,
                        data._version,
                        props.setDeleteVisibility,
                        props.setVersion,
                        props.setID
                      )
                    }
                  >
                    Delete
                  </Dropdown.Item>
                  {/* Mark prayer request as complete */}
                  <Dropdown.Item
                    onClick={() =>
                      props.handleCompletion(
                        data.id,
                        data.title,
                        data.note,
                        data._version
                      )
                    }
                  >
                    Complete
                  </Dropdown.Item>
                  {/* Add a comment to the prayer request */}
                  <Dropdown.Item onClick={() => props.handleComment(data.id)}>
                    Comment
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default RenderAsList;
