// Import necessary styles and code
import { withAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import pages and components
import AboutUs from "./pages/AboutUs.jsx";
import PrayerRequestPage from "./pages/PrayerRequestPage.js";
import Profile from "./pages/Profile.js";
import Navigation from "./components/Navigation.js";
import Groups from "./pages/Groups.js";
import { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import * as mutation from "./graphql/mutations";
import config from "./amplifyconfiguration.json";
import { fetchUserAttributes } from "aws-amplify/auth";
import * as util from "./utils/PrayerUtils.js";
import { listComments, listUsers } from "./graphql/queries";
import * as groupUtil from "./utils/GroupUtil.js";
import * as subscription from "./graphql/subscriptions.js";
// import { CONNECTION_STATE_CHANGE } from "aws-amplify/api";
// import { Hub } from "aws-amplify/utils";
import PageNotFound from "./pages/PageNotFound.jsx";

// Uncomment to test connection to API
// Hub.listen("api", (data) => {
//   const { payload } = data;
//   if (payload.event === CONNECTION_STATE_CHANGE) {
//     const connectionState = payload.data.connectionState;
//     console.log(connectionState);
//   }
// });

Amplify.configure(config);
const client = generateClient();

// Set up routes for different pages
function App({ signOut }) {
  const [groups, setGroups] = useState();
  const [prayerRequests, setPrayerRequests] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [inProgressRequests, setInProgressRequests] = useState([]);
  const [completeRequests, setCompletedRequests] = useState([]);
  const [comment, setComments] = useState();
  const [userData, setUserData] = useState([]);

  const fetchUserInfo = async () => {
    try {
      const { email } = await fetchUserAttributes();

      const promise = await client.graphql({
        query: listUsers,
        variables: { filter: { email: { eq: email } } },
      });

      // Wait for the promise to resolve
      await promise;

      const userFromAPI = promise.data?.listUsers.items;
      setUserData(userFromAPI);
    } catch (error) {
      // Handle errors
      console.error("Error fetching user info:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  };

  // Fetches the current prayer requests from the API upon the first page render.
  useEffect(() => {
    async function FetchData() {
      await Promise.all([
        util.fetchAll(
          setAllRequests,
          setPrayerRequests,
          setInProgressRequests,
          setCompletedRequests
        ),
        groupUtil.fetchGroups(setGroups),
        fetchComments(),
        fetchUserInfo(),
      ]);
    }
    let cleanupFunction;
    async function Subscribe() {
      const { email } = await fetchUserAttributes();
      const createUserSub = client
        .graphql({
          query: subscription.onCreateUser,
          variables: { filter: { email: { eq: email } } },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "User Created");
            fetchUserInfo();
          },
          error: (error) => console.warn(error),
        });
      const updateUserSub = client
        .graphql({
          query: subscription.onUpdateUser,
          variables: { filter: { email: { eq: email } } },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "User Updated");
            fetchUserInfo();
          },
          error: (error) => console.warn(error),
        });
      const createGroupSub = client
        .graphql({
          query: subscription.onCreateUserPrayerGroups,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { ne: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "User Group Created");
            groupUtil.fetchGroups(setGroups);
          },
          error: (error) => console.warn(error),
        });
      const updateGroupSub = client
        .graphql({
          query: subscription.onUpdateUserPrayerGroups,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { ne: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "User Group Updated");
            groupUtil.fetchGroups(setGroups);
          },
          error: (error) => console.warn(error),
        });
      const deleteGroupSub = client
        .graphql({
          query: subscription.onDeleteUserPrayerGroups,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { eq: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "User Group Deleted");
            groupUtil.fetchGroups(setGroups);
          },
          error: (error) => console.warn(error),
        });
      const createCommentSub = client
        .graphql({
          query: subscription.onCreateComments,
          variables: {
            filter: { _deleted: { ne: true } },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Comment Created");
            fetchComments();
          },
          error: (error) => console.warn(error),
        });
      const editCommentSub = client
        .graphql({
          query: subscription.onUpdateComments,
          variables: {
            filter: { _deleted: { ne: true } },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Comment Updated");
            fetchComments();
          },
          error: (error) => console.warn(error),
        });
      const deleteCommentSub = client
        .graphql({
          query: subscription.onDeleteComments,
          variables: {
            filter: { _deleted: { eq: true } },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Comment Deleted");
            fetchComments();
          },
          error: (error) => console.warn(error),
        });
      const createSub = client
        .graphql({
          query: subscription.onCreatePrayerRequest,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { ne: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Request Created");
            util.fetchAll(
              setAllRequests,
              setPrayerRequests,
              setInProgressRequests,
              setCompletedRequests
            );
          },
          error: (error) => console.warn(error),
        });
      const editSub = client
        .graphql({
          query: subscription.onUpdatePrayerRequest,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { ne: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Request Updated");
            util.fetchAll(
              setAllRequests,
              setPrayerRequests,
              setInProgressRequests,
              setCompletedRequests
            );
          },
          error: (error) => console.warn(error),
        });
      const deleteSub = client
        .graphql({
          query: subscription.onDeletePrayerRequest,
          variables: {
            filter: {
              user: { eq: email },
              and: { _deleted: { eq: true } },
            },
          },
        })
        .subscribe({
          next: (value) => {
            // console.log(value, "Request Deleted");
            util.fetchAll(
              setAllRequests,
              setPrayerRequests,
              setInProgressRequests,
              setCompletedRequests
            );
          },
          error: (error) => console.warn(error),
        });
      return () => {
        createUserSub.unsubscribe();
        updateUserSub.unsubscribe();
        createGroupSub.unsubscribe();
        deleteGroupSub.unsubscribe();
        updateGroupSub.unsubscribe();
        createCommentSub.unsubscribe();
        editCommentSub.unsubscribe();
        deleteCommentSub.unsubscribe();
        editSub.unsubscribe();
        createSub.unsubscribe();
        deleteSub.unsubscribe();
      };
    }
    Subscribe();
    FetchData();
    return () => cleanupFunction?.call();
  }, []);

  async function fetchComments() {
    try {
      const promise = await client.graphql({
        query: listComments,
        variables: { filter: { _deleted: { ne: true } } },
      });

      // Wait for the promise to resolve
      await promise;

      const commentsFromAPI = promise.data.listComments.items;
      setComments(commentsFromAPI);
    } catch (error) {
      // Handle errors
      console.error("Error fetching comments:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  // This function fetches the prayer request data from the API.

  // This function creates a new prayer request and sends the new data
  // to the API.
  async function createPrayerRequest(prayerData) {
    try {
      const { email } = await fetchUserAttributes();
      const data = {
        title: prayerData.title,
        note: prayerData.note,
        user: email,
        completed: prayerData.completed,
        completedNote: prayerData.completedNote,
      };
      const promise = await client.graphql({
        query: mutation.createPrayerRequest,
        variables: { input: data },
      });

      // Wait for the promise to resolve
      await promise;
    } catch (error) {
      // Handle errors
      console.error("Error creating prayer request:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  // This function deletes individual prayer requests and also removes
  // them from the API.
  async function deletePrayerRequest(dataid, version) {
    try {
      const data = {
        id: dataid,
        _version: version,
      };

      const promise = client.graphql({
        query: mutation.deletePrayerRequest,
        variables: { input: data },
      });

      // Wait for the promise to resolve
      await promise;
    } catch (error) {
      // Handle errors
      console.error("Error deleting prayer request:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  // Updates an individual prayer request and updates the API.
  async function updatePrayerRequest(dataid, newTitle, newNote, version) {
    try {
      const updateRequestDetails = {
        id: dataid,
        title: newTitle,
        note: newNote,
        _version: version,
      };

      await client.graphql({
        query: mutation.updatePrayerRequest,
        variables: {
          input: updateRequestDetails,
        },
      });
    } catch (error) {
      // Handle errors
      console.error("Error updating prayer request:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  return (
    <View className="App">
      <BrowserRouter>
        <div className="app-div">
          <Navigation
            // setPrayerRequests={setPrayerRequests}
            // inProgressRequests={inProgressRequests}
            signOut={signOut}
          />
          <Routes>
            <Route
              path="/"
              element={
                <PrayerRequestPage
                  comment={comment}
                  inProgressRequests={inProgressRequests}
                  prayerRequests={prayerRequests}
                  completeRequests={completeRequests}
                  allRequests={allRequests}
                  groups={groups}
                  createPrayerRequest={createPrayerRequest}
                  deletePrayerRequest={deletePrayerRequest}
                  updatePrayerRequest={updatePrayerRequest}
                />
              }
            ></Route>
            <Route path="/about" element={<AboutUs />}></Route>
            <Route
              path="/profile"
              element={<Profile userData={userData} />}
            ></Route>
            <Route
              path="/groups"
              element={
                <Groups
                  comment={comment}
                  inProgressRequests={inProgressRequests}
                  prayerRequests={prayerRequests}
                  completeRequests={completeRequests}
                  allRequests={allRequests}
                  groups={groups}
                  createPrayerRequest={createPrayerRequest}
                  deletePrayerRequest={deletePrayerRequest}
                  updatePrayerRequest={updatePrayerRequest}
                />
              }
            ></Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </View>
  );
}

export default withAuthenticator(App);
