// Import necessary dependencies and components
import ModalBody from "react-bootstrap/esm/ModalBody";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as util from "../../utils/PrayerUtils";

// Define the DeleteRequestModal component
const DeleteRequestModal = (props) => {
  // Render the modal dialog box
  return (
    <Modal
      style={{ marginTop: "10%" }}
      show={props.isDeleteModalVisible}
      onHide={() => props.setDeleteVisibility(false)}
    >
      {/* Modal header */}
      <Modal.Header>Delete Confirmation</Modal.Header>
      {/* Modal body */}
      <ModalBody>
        {/* Confirmation message */}
        <p>Are you sure you would like to delete this prayer request?</p>
        {/* Buttons for confirming deletion or closing the modal */}
        <div style={{ display: "flex" }}>
           {/* Close button */}
           <Button
            style={{ marginRight: "10px" }}
            variant="secondary"
            onClick={() => props.setDeleteVisibility(false)}
          >
            Close
          </Button>
          {/* Delete button */}
          <Button
            onClick={() =>
              util.updateDeleteHandler(
                props.deletePrayerRequest,
                props.currentID,
                props.currentVersion,
                props.setDeleteVisibility
              )
            }
          >
            Delete
          </Button>
         
        </div>
      </ModalBody>
    </Modal>
  );
};

// Export the DeleteRequestModal component
export default DeleteRequestModal;