// Import necessary dependencies and components
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as util from "../../utils/PrayerUtils";
import ModalControlButtons from "../ModalControlButtons";

// Define the CreateRequestModal component
const CreateRequestModal = (props) => {
  // State variables for form errors and form data
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // Render the modal dialog box
  return (
    <Modal
      show={props.showModal}
      onHide={() => util.handleClose(props.setShowModal, setErrors)}
    >
      {/* Modal header */}
      <Modal.Header>Create Prayer Request</Modal.Header>
      {/* Form for creating a prayer request */}
      <Form
        noValidate
        className="signup-form"
        onSubmit={(event) =>
          util.handleCreation(
            event,
            form,
            setErrors,
            setForm,
            props.createPrayerRequest,
            props.setShowModal,
            false
          )
        }
      >
        {/* Input field for the title of the prayer request */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="title"
            placeholder="Title"
            onChange={(e) =>
              util.setField(
                "title",
                e.target.value,
                setForm,
                setErrors,
                form,
                errors
              )
            }
            isInvalid={!!errors.title}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Textarea field for the prayer request description */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Journal Entry</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter prayer request."
            onChange={(e) =>
              util.setField(
                "note",
                e.target.value,
                setForm,
                setErrors,
                form,
                errors
              )
            }
            type="note"
            isInvalid={!!errors.note}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.note}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Modal control buttons */}
        <ModalControlButtons
          style={{ margin: "15px 5px 15px 0" }}
          closeButton={() => util.handleClose(props.setShowModal, setErrors)}
        />
      </Form>
    </Modal>
  );
};

// Export the CreateRequestModal component
export default CreateRequestModal;