// Import necessary dependencies and components
import "./PrayerRequest.css";
import { useState } from "react";
import DeleteRequestModal from "./DeleteRequestModal";
import EditRequestModal from "./EditRequestModal";
import CompleteRequestModal from "./CompleteRequestModal";
import ExpandRequestModal from "./ExpandRequestModal";
import CommentRequestModal from "./CommentRequestModal";
import ViewCommentsModal from "./ViewCommentsModal";
import RenderAsList from "./RenderAsList";
import RenderAsTile from "./RenderAsTile";

// Define the PrayerRequest component
function PrayerRequest(props) {
  // State variables for modals and dropdowns
  const [isVisible, setShowModal] = useState(false);
  const [isDeleteModalVisible, setDeleteVisibility] = useState(false);
  const [currentID, setID] = useState();
  const [currentVersion, setVersion] = useState();
  const [form, setForm] = useState({});
  const [completeVisible, setCompleteVisibility] = useState(false);
  const [title, setTitle] = useState();
  const [note, setNote] = useState();
  const [expandModal, setExpandModal] = useState(false);
  const [completeNote, setCompleteNote] = useState();
  const [createdAt, setCreatedAt] = useState("");
  const [completed, setCompleted] = useState(false);
  const [commentModalVis, setCommentVis] = useState(false);
  const [viewCommentsModalVis, setViewCommentModalVis] = useState(false);

  // Handle the completion of a prayer request
  const handleCompletion = (id, title, note, version) => {
    setCompleteVisibility(true);
    setVersion(version);
    setID(id);
    setNote(note);
    setTitle(title);
  };

  // Handle adding a comment to a prayer request
  const handleComment = (id) => {
    setID(id);
    setCommentVis(true);
  };

  // Handle expanding a prayer request to show more details
  const handleExpansion = (cTitle, note, cNote, date, completed, id) => {
    setExpandModal(true);
    setTitle(cTitle);
    setNote(note);
    setCompleteNote(cNote);
    setCreatedAt(date);
    setCompleted(completed);
    setID(id);
  };

  // Handle the selection of a prayer request
  const handleSelection = (id) => {
    if (props.selectedList.has(id)) {
      props.selectedList.delete(id);
    } else {
      props.selectedList.add(id);
    }
    props.setSelectedList(new Set(props.selectedList));
  };

  const expandCommentsModal = (id) => {
    setViewCommentModalVis(true);
    setID(id);
  };

  return (
    <div
      className={props.listStyle ? "prayerequestbox-list" : "prayerequestbox"}
    >
      {/* Render table headers if listStyle is true and there are prayer requests */}
      {props.prayerRequests?.length > 0 && props.listStyle && (
        <RenderAsList
          selectedList={props.selectedList}
          prayerRequests={props.prayerRequests}
          handleCompletion={handleCompletion}
          handleSelection={handleSelection}
          setShowModal={setShowModal}
          setID={setID}
          setForm={setForm}
          setVersion={setVersion}
          form={form}
          setDeleteVisibility={setDeleteVisibility}
          handleComment={handleComment}
          expandCommentsModal={expandCommentsModal}
          handleExpansion={handleExpansion}
          comment={props.comment}
        />
      )}
      {/* Render prayer requests in a grid view */}
      {!props.listStyle && (
        <RenderAsTile
          selectedList={props.selectedList}
          prayerRequests={props.prayerRequests}
          handleCompletion={handleCompletion}
          handleSelection={handleSelection}
          setShowModal={setShowModal}
          setID={setID}
          setForm={setForm}
          setVersion={setVersion}
          form={form}
          setDeleteVisibility={setDeleteVisibility}
          handleComment={handleComment}
          expandCommentsModal={expandCommentsModal}
          handleExpansion={handleExpansion}
          comment={props.comment}
        />
      )}

      {/* ExpandRequestModal component */}
      {createdAt && (
        <ExpandRequestModal
          completed={completed}
          title={title}
          note={note}
          completeNote={completeNote}
          createdAt={createdAt}
          expandModal={expandModal}
          setExpandModal={setExpandModal}
          comment={props.comment}
          currentID={currentID}
        />
      )}
      {/* CompleteRequestModal component */}
      <CompleteRequestModal
        comment={props.comment}
        completeVisible={completeVisible}
        setCompleteVisibility={setCompleteVisibility}
        note={note}
        title={title}
        id={currentID}
        version={currentVersion}
        deletePrayerRequest={props.deletePrayerRequest}
        setPrayerRequests={props.setPrayerRequests}
      />
      {/* EditRequestModal component */}
      <EditRequestModal
        isVisible={isVisible}
        setShowModal={setShowModal}
        form={form}
        setForm={setForm}
        updatePrayerRequest={props.updatePrayerRequest}
        currentID={currentID}
        version={currentVersion}
      />
      {/* DeleteRequestModal component */}
      <DeleteRequestModal
        isDeleteModalVisible={isDeleteModalVisible}
        currentID={currentID}
        currentVersion={currentVersion}
        deletePrayerRequest={props.deletePrayerRequest}
        setDeleteVisibility={setDeleteVisibility}
      />
      {/* CommentRequestModal component */}
      <CommentRequestModal
        id={currentID}
        commentModalVis={commentModalVis}
        setCommentVis={setCommentVis}
      />
      <ViewCommentsModal
        viewCommentsModalVis={viewCommentsModalVis}
        setViewCommentModalVis={setViewCommentModalVis}
        comment={props.comment}
        currentID={currentID}
      />
    </div>
  );
}

// Export the PrayerRequest component
export default PrayerRequest;
