// Import necessary dependencies and components
import React, { useState } from "react";
import GroupTitleBar from "../components/Grouping/GroupTitleBar";
import GroupList from "../components/Grouping/GroupList";
import * as mutation from "../graphql/mutations";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import config from "../amplifyconfiguration.json";
import { fetchUserAttributes } from "aws-amplify/auth";
import PrayerList from "../components/Prayer Request/PrayerList";

// Configure AWS Amplify with the provided configuration
Amplify.configure(config);
// Generate an API client using AWS Amplify
const client = generateClient();

// Define the Groups component
function Groups(props) {
  // State variables for the modal visibility, group title, and category
  const [showModal, setShowModal] = useState(false);
  const [showCategory, setCategory] = useState("groups");
  const [groupId, setGroupId] = useState();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
  filter.removeWords("God");

  const validateForm = (form) => {
    const { groupName } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (!groupName || groupName === "" || groupName.length >= 50)
      newErrors.groupName =
        "Please enter a valid group name (1 to 50 characters).";
    if (specialChars.test(groupName)) newErrors.groupName = charMessage;
    if (groupName?.trim().length === 0)
      newErrors.groupName = "Please enter a valid name. Not just whitespace.";
    if (filter.isProfane(groupName))
      newErrors.groupName = "Please do not use profanity in your group name.";
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  // Handle the creation of a new group
  async function handleGroupCreation(event) {
    event.preventDefault();
    try {
      // Fetch the user's email address
      const { email } = await fetchUserAttributes();
      const formErrors = validateForm(form);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        // Prepare the data for the new group
        const data = {
          groupName: form.groupName,
          user: email,
          prayerIds: "",
        };
        // Send a GraphQL mutation to create the group
        const promise = await client.graphql({
          query: mutation.createUserPrayerGroups,
          variables: { input: data },
        });
        // Wait for the promise to resolve
        await promise;
        setForm({});
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error creating group:", error);

      // Check for network errors
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error(
          "Network error occurred. Please check your internet connection."
        );
      } else {
        // Handle other unexpected errors
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  // Render the group list and prayer list
  return (
    <div className="page-layout" style={{ height: "100vh" }}>
      <div
        style={{
          overflow: "none",
          display: "block",
          width: "100%",
          height: "100vh",
        }}
      >
        {/* Render the group list if the category is "groups" */}
        {showCategory === "groups" && (
          <div>
            {/* GroupTitleBar component for creating a new group */}
            <GroupTitleBar
              handleGroupCreation={handleGroupCreation}
              setShowModal={setShowModal}
              showModal={showModal}
              setField={setField}
              errors={errors}
              form={form}
              setForm={setForm}
              setErrors={setErrors}
            />

            {/* GroupList component for displaying the list of groups */}
            <GroupList
              setGroupId={setGroupId}
              showCategory={showCategory}
              setCategory={setCategory}
              prayerRequests={props.allRequests}
              groups={props.groups}
            />
          </div>
        )}
        {/* Render the prayer list if the category is not "groups" */}
        {showCategory !== "groups" && (
          // PrayerList component for displaying the list of prayer requests
          <PrayerList
            inProgressRequests={props.inProgressRequests}
            prayerRequests={props.allRequests.filter((value) =>
              props.groups
                .find((value) => value.id === groupId)
                .prayerIds.includes(value.id)
            )}
            completeRequests={props.completeRequests}
            allRequests={props.allRequests}
            setCategory={setCategory}
            groupName={showCategory}
            groups={props.groups}
            createPrayerRequest={props.createPrayerRequest}
            deletePrayerRequest={props.deletePrayerRequest}
            updatePrayerRequest={props.updatePrayerRequest}
            completePrayerRequest={props.completePrayerRequest}
            comment={props.comment}
          />
        )}
      </div>
    </div>
  );
}

// Export the Groups component
export default Groups;
