/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      note
      prayerrequestID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserPrayerGroups = /* GraphQL */ `
  mutation CreateUserPrayerGroups(
    $input: CreateUserPrayerGroupsInput!
    $condition: ModelUserPrayerGroupsConditionInput
  ) {
    createUserPrayerGroups(input: $input, condition: $condition) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserPrayerGroups = /* GraphQL */ `
  mutation UpdateUserPrayerGroups(
    $input: UpdateUserPrayerGroupsInput!
    $condition: ModelUserPrayerGroupsConditionInput
  ) {
    updateUserPrayerGroups(input: $input, condition: $condition) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserPrayerGroups = /* GraphQL */ `
  mutation DeleteUserPrayerGroups(
    $input: DeleteUserPrayerGroupsInput!
    $condition: ModelUserPrayerGroupsConditionInput
  ) {
    deleteUserPrayerGroups(input: $input, condition: $condition) {
      id
      groupName
      user
      prayerIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      age
      bio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createPrayerRequest = /* GraphQL */ `
  mutation CreatePrayerRequest(
    $input: CreatePrayerRequestInput!
    $condition: ModelPrayerRequestConditionInput
  ) {
    createPrayerRequest(input: $input, condition: $condition) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePrayerRequest = /* GraphQL */ `
  mutation UpdatePrayerRequest(
    $input: UpdatePrayerRequestInput!
    $condition: ModelPrayerRequestConditionInput
  ) {
    updatePrayerRequest(input: $input, condition: $condition) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePrayerRequest = /* GraphQL */ `
  mutation DeletePrayerRequest(
    $input: DeletePrayerRequestInput!
    $condition: ModelPrayerRequestConditionInput
  ) {
    deletePrayerRequest(input: $input, condition: $condition) {
      id
      title
      completedNote
      note
      completed
      user
      Comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
