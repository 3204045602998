import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { listPrayerRequests } from "../graphql/queries";
import config from "../amplifyconfiguration.json";
import { fetchUserAttributes } from "aws-amplify/auth";

Amplify.configure(config);
const client = generateClient();

// eslint-disable-next-line
const specialChars = /[#^*_+=\[\]{}|<>~]/;
const Filter = require("bad-words"),
  filter = new Filter();
filter.removeWords("God");

// ! Test Validation handling and make sure that there are no validation bugs and no SQL Injeciton can happen.
export const validateForm = (form) => {
  const { title, note } = form;
  const newErrors = {};
  const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
  if (!title || title === "" || title.length >= 30)
    newErrors.title = "Please enter a valid title (1 to 30 characters).";
  if (title?.trim().length === 0)
    newErrors.title = "Please enter a valid title. Not just whitespace.";
  if (!note || note === "" || note.length >= 300)
    newErrors.note = "Please enter a valid description (1 to 300 characters).";
  if (note?.trim().length === 0)
    newErrors.note = "Please enter a valid description. Not just whitespace.";
  if (specialChars.test(title)) newErrors.title = charMessage;
  if (specialChars.test(note)) newErrors.note = charMessage;
  if (filter.isProfane(title))
    newErrors.title = "Please do not use profanity in your request.";
  if (filter.isProfane(note))
    newErrors.note = "Please do not use profanity in your request.";
  return newErrors;
};

export const handleModal = (setShowModal) => {
  setShowModal(true);
};

export const handleClose = (setShowModal, setErrors) => {
  setShowModal(false);
  setErrors({});
};

export const handleCreation = (
  event,
  form,
  setErrors,
  setForm,
  addPrayerHandler,
  setShowModal
) => {
  event.preventDefault();
  const formErrors = validateForm(form);
  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
  } else {
    const prayerData = {
      title: form.title,
      note: form.note,
      completed: false,
      completedNote: "",
    };
    addPrayerHandler(prayerData);
    setShowModal(false);
    setForm({});
  }
};

export const fetchNotes = async (setPrayerRequests) => {
  try {
    const { email } = await fetchUserAttributes();
    const filter = {
      filter: {
        user: { eq: email },
        and: { _deleted: { attributeExists: false } },
        // eslint-disable-next-line
        completed: { eq: false },
      },
    };
    const promise = await client.graphql({
      query: listPrayerRequests,
      variables: filter,
    });
    const prayersFromAPI = promise.data.listPrayerRequests.items;
    setPrayerRequests(prayersFromAPI);
  } catch (error) {
    console.error("Error fetching notes:", error);

    // Check for network errors
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error(
        "Network error occurred. Please check your internet connection."
      );
    } else {
      // Handle other unexpected errors
      console.error("An unexpected error occurred:", error.message);
    }
  }
};

export const fetchNotesWithFilter = async (
  filter,
  setAllRequests,
  setPrayerRequests,
  setInProgressRequests,
  setCompletedRequests
) => {
  try {
    const promise = await client.graphql({
      query: listPrayerRequests,
      variables: filter,
    });
    const prayersFromAPI = promise.data.listPrayerRequests.items;
    setPrayerRequests(prayersFromAPI.filter((x) => !x.completed));
    setAllRequests(prayersFromAPI);
    setInProgressRequests(prayersFromAPI.filter((x) => !x.completed));
    setCompletedRequests(prayersFromAPI.filter((x) => x.completed));
  } catch (error) {
    console.error("Error fetching notes with filter:", error);

    // Check for network errors
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error(
        "Network error occurred. Please check your internet connection."
      );
    } else {
      // Handle other unexpected errors
      console.error("An unexpected error occurred:", error.message);
    }
  }
};

export async function fetchAll(
  setAllRequests,
  setPrayerRequests,
  setInProgressRequests,
  setCompletedRequests
) {
  const { email } = await fetchUserAttributes();
  const filter = {
    filter: {
      user: { eq: email },
      and: { _deleted: { attributeExists: false } },
    },
  };
  fetchNotesWithFilter(
    filter,
    setAllRequests,
    setPrayerRequests,
    setInProgressRequests,
    setCompletedRequests
  );
}

export const handleUpdate = (
  event,
  form,
  setIsVisible,
  setErrors,
  currentID,
  version,
  updatePrayerRequest
) => {
  event.preventDefault();

  const formErrors = validateForm(form);
  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
  } else {
    updatePrayerRequest(currentID, form.title, form.note, version);
    setIsVisible(false);
  }
};

export const setField = (field, value, setForm, setErrors, form, errors) => {
  setForm({
    ...form,
    [field]: value,
  });

  if (!!errors[field]) {
    setErrors({
      ...errors,
      [field]: null,
    });
  }
};

export const editPrayerRequest = (
  id,
  title,
  note,
  version,
  setIsVisible,
  setID,
  setForm,
  setVersion,
  form
) => {
  setIsVisible(true);
  setID(id);
  setForm({ ...form, title: title, note: note });
  setVersion(version);
};

export const deletePrayerRequestConfirmationHandler = (
  id,
  version,
  setDeleteVisibility,
  setVersion,
  setID
) => {
  setDeleteVisibility(true);
  setVersion(version);
  setID(id);
};

export const updateDeleteHandler = (
  deletePrayerRequest,
  id,
  version,
  setDeleteVisibility
) => {
  deletePrayerRequest(id, version);
  setDeleteVisibility(false);
};
