// Import necessary dependencies and components
import "./PrayerList.css";
import PrayerListTitleBar from "./PrayerListTitleBar";
import PrayerRequest from "./PrayerRequest";
import { useState } from "react";

// Define the PrayerList component
function PrayerList(props) {
  // State variables for list style, selected prayer requests, and comments
  const [listStyle, setListStyle] = useState();
  const [selectedList, setSelectedList] = useState(new Set());
  const [filter, setFilter] = useState("all");

  // Render the prayer list
  return (
    <div className="prayer-list-box">
      {/* PrayerListTitleBar component */}
      <PrayerListTitleBar
        setFilter={setFilter}
        allRequests={props.allRequests}
        setCategory={props.setCategory}
        groupName={props.groupName}
        groups={props.groups}
        createPrayerRequest={props.createPrayerRequest}
        setListStyle={setListStyle}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        prayerRequests={props.prayerRequests}
      />

      {/* PrayerRequest component */}
      <PrayerRequest
        groupName={props.groupName}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        listStyle={listStyle}
        prayerRequests={
          props.groupName !== "Prayer Requests"
            ? props.prayerRequests
            : filter === "all"
            ? props.allRequests
            : filter === "in-progress"
            ? props.inProgressRequests
            : props.completeRequests
        }
        deletePrayerRequest={props.deletePrayerRequest}
        updatePrayerRequest={props.updatePrayerRequest}
        comment={props.comment}
        setPrayerRequests={props.setPrayerRequests}
      />
    </div>
  );
}

// Export the PrayerList component
export default PrayerList;
