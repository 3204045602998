//import { useState } from "react";
import "./UserProfile.css";
import "react-tabs/style/react-tabs.css";
import Gravatar from "react-gravatar";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useState, useEffect } from "react";
import CreateUserModal from "./CreateUserModal";
import { Button } from "react-bootstrap";
import EditUserModal from "./EditUserModal";
import SignOutButton from "../components/SignOutButton";
import harmonyQR from "../components/harmonyQR.png";
import gravatarQR from "../components/gravatarQR.png";

const UserProfile = (props) => {
  const [createModalVis, setCreateModalVis] = useState(false);
  const [editModalVis, setEditModalVis] = useState(false);
  const [email, setEmail] = useState();
  const [userID, setUserId] = useState();
  const [userVersion, setVersion] = useState();

  useEffect(() => {
    setUserEmail();
  }, []);

  const setUserEmail = async () => {
    const { email } = await fetchUserAttributes();
    setEmail(email);
  };

  const handleEditOpen = () => {
    setEditModalVis(true);
    setUserId(props.userData[0]?.id);
    setVersion(props.userData[0]?._version);
  };

  return (
    <div className="full-page">
      <div className="userProfile">
        {email && (
          <a href="https://gravatar.com">
            <Gravatar
              email={email}
              size={220}
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
          </a>
        )}
        <div>
          {props.userData?.length > 0 && (
            <div key={props.userData[0]?.id}>
              <h2>
                {props.userData[0]?.firstName +
                  " " +
                  props.userData[0]?.lastName}
              </h2>
              <br />
              <h4>Personal Bio</h4>
              <h6 style={{ maxWidth: "300px" }}>{props.userData[0]?.bio}</h6>
              <h5>Age: {props.userData[0]?.age}</h5>
            </div>
          )}
          <br></br>
          {props.userData?.length === 0 ? (
            <Button
              onClick={() => setCreateModalVis(true)}
              className="userProfile-button"
              style={{ textDecoration: "none" }}
            >
              Create Profile
            </Button>
          ) : (
            <Button
              onClick={() => handleEditOpen()}
              className="userProfile-button"
              style={{ textDecoration: "none" }}
            >
              Edit Profile
            </Button>
          )}
          <SignOutButton />
        </div>
        <CreateUserModal
          createModalVis={createModalVis}
          setCreateModalVis={setCreateModalVis}
        />
        {props.userData?.length > 0 && (
          <EditUserModal
            userData={props.userData}
            userVersion={userVersion}
            userID={userID}
            editModalVis={editModalVis}
            setEditModalVis={setEditModalVis}
          />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
