// Import necessary dependencies and components
import { ModalBody } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";

// Define the ExpandRequestModal component
const ExpandRequestModal = (props) => {
  // Render the modal dialog box
  return (
    <Modal show={props.expandModal} onHide={() => props.setExpandModal(false)}>
      {/* Modal header */}
      <Modal.Header>
        <h4>{props.title}</h4>
      </Modal.Header>
      {/* Modal body */}
      <ModalBody>
        {/* Prayer request description */}
        <p>{props.note}</p>

        {/* Creation date and time */}
        <p>{format(props.createdAt, "MMMM dd, yyyy 'at' h:mmaaaaa'm'")}</p>
        
        {/* Answer to the prayer request */}
        {props.completed && <h5>Answer</h5>}
        <p>{props.completeNote}</p>

        {/* Comments on the prayer request */}
        {props.comment?.some((value) => value.prayerrequestID === props.currentID) && <h5>Comments</h5>}
        {/* eslint-disable-next-line */}
        {props.comment?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((value) => {
          if (value.prayerrequestID === props.currentID) {
              return (
                <div key={value.id}>
                  {value.note}
                  <p style={{ marginTop: "10px" }}>
                    {format(value.createdAt, "h:mmaaaaa'm'")}
                    <br />
                    {format(value.createdAt, "MMMM dd, yyyy")}
                  </p>
                </div>
              )}})}
      </ModalBody>
    </Modal>
  );
};

// Export the ExpandRequestModal component
export default ExpandRequestModal;