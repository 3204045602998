import "./AboutUs.css";
import Joe from "../../src/Team-pics/Swanson.jpg";
import Andrew from "../../src/Team-pics/Andrew.jpg";
import Zach from "../../src/Team-pics/Zach.jpg";
import Jake from "../../src/Team-pics/Jake.jpg";
import Jose from "../../src/Team-pics/Jose.jpg";
import MemberCard from "../components/MemberCard";

// This function will render the About Us page it also contains the dynamic
// MemberCard component which renders each member of the team.
function AboutUs() {
  return (
    <div className="about-page-layout">
      <div className="about-section">
        <p className="About-Us"> About Us</p>
        <div
          style={{
            width: "75%",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <p>
            Harmony Prayer is composed of a team of developers based out of
            Pensacola, Florida. The team has merged their different knacks and
            fields of expertise to create a web-hosted application targeting
            small churches.
          </p>
        </div>
      </div>
      <div className="team-row">
        <h2
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "",
            paddingBottom: "20px",
          }}
        >
          Our Team
        </h2>

        <div className="first-two-members">
          {/* The Project Manager's role description */}
          <MemberCard
            image={Joe}
            name="Joseph Swanson"
            title="Project Manager"
            description="As Team Leader, Joe assigns responsibilities
            to the rest of the team, ensures that the deadlines are met
            and creates the majority of the front-end of the application."
          />

          <MemberCard
            // The Quality Administrator's role description
            image={Zach}
            name="Zachary Schultz"
            title="Quality Assurance"
            description="Zach ensures coding standards are up to par,
            reviews the code for consistency throughout, and finds
            innovative ways to optimize and reduce the workload for the team."
          />
          
        </div>

        <div className="last-three-members">
          <MemberCard
            // The Database Adminsitrator's role description
            image={Andrew}
            name="Andrew Ranck"
            title="Database Administrator"
            description="Andrew manages the backend and ensures that the
            database is properly hooked up to the application. Additionally,
            he codes functionality into the database."
          />

          <MemberCard
            // The System Administrator's role description
            image={Jose}
            name="Jose Porras"
            title="System Administrator"
            description="As the System Administrator, Jose makes sure that the
            application runs smoothly by managing the domain and the server
            through the regular testing of bugs."
          />
          <MemberCard
            // The Cybersecurity Specialist's role description
            image={Jake}
            name="Jacob Hopkins"
            title="Cybersecurity Specialist"
            description="Jake ensures cybersecurity policies are met and manages
            the Risk Management Framework. He plays a pivotal role in keeping
            our many users' data safe."
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;