import { format } from "date-fns";
import * as util from "../../utils/PrayerUtils";
import Dropdown from "react-bootstrap/Dropdown";
import { PiDotsThree } from "react-icons/pi";
import { MdExpandMore } from "react-icons/md";
import { MdCircle } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";

const RenderAsTile = (props) => {
  return props.prayerRequests
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((data) => (
      <div key={data.id} className="prayer-requests">
        {/* Prayer request title and actions */}
        <div style={{ display: "flex", position: "relative" }}>
          <h5 className="prayer-title">
            {" "}
            {data.title.length > 8
              ? `${data.title.substring(0, 8)}...`
              : data.title}
          </h5>

          {/* Checkbox and expand button */}
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              marginRight: "45px",
              marginTop: "5px",
            }}
          >
            {/* Unchecked checkbox */}
            {!props.selectedList.has(data.id) ? (
              <MdOutlineCheckBoxOutlineBlank
                style={{
                  fontSize: "22px",
                }}
                title="Select for grouping"
                onClick={() => props.handleSelection(data.id)}
              />
            ) : (
              <MdOutlineCheckBox
                onClick={() => props.handleSelection(data.id)}
                style={{
                  fontSize: "22px",
                }}
                title="Selected for grouping"
              />
            )}

            <MdExpandMore
              title="Expand request"
              onClick={() =>
                props.handleExpansion(
                  data.title,
                  data.note,
                  data.completedNote,
                  data.createdAt,
                  data.completed,
                  data.id
                )
              }
              style={{
                color: "black",
                fontSize: "32px",
                backgroundColor: "transparent",
                border: "none",
              }}
            />
          </div>

          {/* Dropdown menu for actions */}
          <Dropdown
            style={{
              position: "absolute",
              top: "-5px",
              right: "0px",
              marginRight: "15px",
            }}
            className="float-right"
          >
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "40px",
                margin: "0px",
                zIndex: "99",
              }}
              id="dropdown-basic"
            >
              <PiDotsThree
                style={{
                  color: "black",
                  fontSize: "32px",
                  marginLeft: "75px",
                  width: "32px",
                }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* Edit prayer request */}
              <Dropdown.Item
                onClick={() =>
                  util.editPrayerRequest(
                    data.id,
                    data.title,
                    data.note,
                    data._version,
                    props.setShowModal,
                    props.setID,
                    props.setForm,
                    props.setVersion,
                    props.form
                  )
                }
              >
                Edit
              </Dropdown.Item>
              {/* Delete prayer request */}
              <Dropdown.Item
                onClick={() =>
                  util.deletePrayerRequestConfirmationHandler(
                    data.id,
                    data._version,
                    props.setDeleteVisibility,
                    props.setVersion,
                    props.setID
                  )
                }
              >
                Delete
              </Dropdown.Item>
              {/* Mark prayer request as complete */}
              <Dropdown.Item
                onClick={() =>
                  props.handleCompletion(
                    data.id,
                    data.title,
                    data.note,
                    data._version
                  )
                }
              >
                Complete
              </Dropdown.Item>
              {/* Add a comment to the prayer request */}
              <Dropdown.Item onClick={() => props.handleComment(data.id)}>
                Comment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Prayer request description */}
        <p className="prayer-descr">
          {" "}
          {data.note.length > 15
            ? `${data.note.substring(0, 15)}...`
            : data.note}
        </p>
        {/* Answer to the prayer request */}
        {data.completed && <h5 className="prayer-title">Answer</h5>}
        <p className="prayer-descr">
          {" "}
          {data?.completedNote?.length > 15
            ? `${data?.completedNote?.substring(0, 15)}...`
            : data?.completedNote}
        </p>
        {/* Comments on the prayer request */}
        {props.comment?.some((value) => value.prayerrequestID === data.id) && (
          <>
            <h5 className="prayer-title">Comment</h5>
            <button
              className="view-more"
              onClick={() => props.expandCommentsModal(data.id)}
            >
              View More
            </button>
          </>
        )}
        {/* Creation date and status indicator */}
        <div>
          <p className="date">
            {format(data.createdAt, "h:mmaaaaa'm'")}
            <br />
            {format(data.createdAt, "MMMM dd, yyyy")}
          </p>
          <p className="md-circle">
            <MdCircle
              title={
                data.completed ? "Status: Completed" : "Status: In Progress"
              }
              style={{
                color: data.completed ? "green" : "yellow",
              }}
            />
          </p>
        </div>
      </div>
    ));
};

export default RenderAsTile;
