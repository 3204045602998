// Import necessary dependencies and components
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as util from "../../utils/PrayerUtils";
import ModalControlButtons from "../ModalControlButtons";
import * as mutation from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import "./CreateRequestModal.css";
import "./CompleteRequestModal.css";

// Generate an API client using AWS Amplify
const client = generateClient();

// Define the CompleteRequestModal component
const CompleteRequestModal = (props) => {
  // State variables for form errors and completed note
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  // eslint-disable-next-line
  const specialChars = /[#^*_+=\[\]{}|<>~]/;
  const Filter = require("bad-words"),
    filter = new Filter();
    filter.removeWords('God');

  const validateForm = (form) => {
    const { note } = form;
    const newErrors = {};
    const charMessage = "No special characters ([#^*_+=[]{}|<>~]).";
    if (!note || note === "" || note.length >= 300)
      newErrors.note =
        "Please enter a valid description (1 to 300 characters).";
    if (note?.trim().length === 0) 
      newErrors.note = "Please enter a valid description. No whitespace.";
    if (specialChars.test(note)) newErrors.note = charMessage;
    if (filter.isProfane(note))
      newErrors.note = "Please do not use profanity in your complete request.";
    return newErrors;
  };

  const setField = (field, value, setForm, setErrors, form, errors) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  // Handle the form submission
  const handleCreation = async (event) => {
    event.preventDefault();
    // Prepare the data for the completed prayer request
    const formErrors = validateForm(form);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Prepare the data for the new prayer request
      const data = {
        _version: props.version,
        id: props.id,
        completed: true,
        completedNote: form.note,
      };
      try {
        // Send a GraphQL mutation to create the prayer request
      const promise = await client.graphql({
        query: mutation.updatePrayerRequest,
        variables: { input: data },
      });
      setForm({});
      props.setCompleteVisibility(false);
      // Wait for the promise to resolve
      await promise;
      } catch (error) {
        // Handle errors
        console.error("Error creating prayer request:", error);
  
        // Check for network errors
        if (error instanceof TypeError && error.message === "Failed to fetch") {
          console.error(
            "Network error occurred. Please check your internet connection."
          );
        } else {
          // Handle other unexpected errors
          console.error("An unexpected error occurred:", error.message);
        }
    }
  };}

  // Render the modal dialog box
  return (
    <Modal
      show={props.completeVisible}
      onHide={() => util.handleClose(props.setCompleteVisibility, setErrors)}
    >
      {/* Modal header */}
      <Modal.Header>Complete Prayer Request</Modal.Header>
      {/* Form for completing the prayer request */}
      <Form
        noValidate
        className="signup-form"
        onSubmit={(event) => handleCreation(event)}
      >
        {/* Input field for the answer to the prayer request */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="answer-modal-label">How was your prayer answered?</Form.Label>
          <Form.Control
            type="answer"
            placeholder="Answer"
            as="textarea"
            onChange={(e) =>
              setField("note", e.target.value, setForm, setErrors, form, errors)
            }
            isInvalid={!!errors.note}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.note}
          </Form.Control.Feedback>
        </Form.Group>
        {/* Modal control buttons */}
        <ModalControlButtons
          style={{ margin: "15px 5px 15px 0" }}
          closeButton={() =>
            util.handleClose(props.setCompleteVisibility, setErrors)
          }
        />
      </Form>
    </Modal>
  );
};

// Export the CompleteRequestModal component
export default CompleteRequestModal;
